<script>
import { createNamespacedHelpers, mapState, mapActions } from 'vuex';

import liveChatInit from '@assets/live-chat';
import zowieChatInit from '@assets/zowie-chat';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ChatDataProvider',

    computed: {
        ...mapState(['isChatWidgetLoading', 'isChatWidgetLoaded']),
        ...mapConfigGetters([
            'isZowieChatEnabled',
            'zowieChatEnabledPages',
            'zowieChatInstanceId',
            'zowieScriptSrc',
        ]),

        chatHelpers() {
            const ZOWIE_CHAT_HELPERS = {
                load: this.loadZowieChat,
                open: this.openZowieChat,
            };

            const LIVECHAT_CHAT_HELPERS = {
                load: this.loadLiveChat,
                open: this.openLiveChat,
            };

            return this.isZowieChatEnabled
                ? ZOWIE_CHAT_HELPERS
                : LIVECHAT_CHAT_HELPERS;
        },
    },

    methods: {
        ...mapActions(['setIsChatWidgetLoading', 'setIsChatWidgetLoaded']),

        openLiveChat() {
            window.LiveChatWidget.call('maximize');
        },

        openZowieChat() {
            window.Chatbotize.open();
        },

        onChatWidgetLoaded() {
            this.setIsChatWidgetLoaded(true);
            this.setIsChatWidgetLoading(false);
        },

        loadZowieChat() {
            const onLoaded = () => {
                this.onChatWidgetLoaded();
                this.openZowieChat();
            };

            zowieChatInit(
                {
                    instanceId: this.zowieChatInstanceId,
                    scriptSrc: this.zowieScriptSrc,
                },
                onLoaded
            );
        },

        loadLiveChat() {
            liveChatInit();

            window.LiveChatWidget.on('ready', () => {
                this.onChatWidgetLoaded();
                this.openLiveChat();
            });
        },

        loadAndOpen() {
            if (this.isChatWidgetLoading) {
                return;
            }

            if (this.isChatWidgetLoaded) {
                this.chatHelpers.open();

                return;
            }

            this.setIsChatWidgetLoading(true);

            this.chatHelpers.load();
        },
    },

    render() {
        return this.$scopedSlots.default({
            isChatWidgetLoading: this.isChatWidgetLoading,
            isChatWidgetLoaded: this.isChatWidgetLoaded,
            loadAndOpenChatWidget: this.loadAndOpen,
            isZowieChatEnabled: this.isZowieChatEnabled,
        });
    },
};
</script>
