<template>
    <div class="dropdown" :class="{ 'has-error': error.length > 0 }">
        <select
            v-if="isSelect"
            ref="select"
            class="select-tag"
            :value="value"
            :disabled="disabled"
            @blur="onBlur($event)"
            @focus="onFocus($event)"
            @change="onChange($event)"
            @keyup.enter="enterKeyup($event)"
        >
            <option
                v-for="{
                    id,
                    value: nativeSelectOptionValue,
                    label,
                } in nativeSelectOptions"
                :key="id"
                :value="nativeSelectOptionValue"
            >
                {{ label }}
            </option>
        </select>

        <WithClickOutsideDetection :handler="opened ? 'close' : null">
            <div class="select" :class="additionalClasses">
                <div class="selected">
                    <button
                        type="button"
                        class="toggle"
                        :tabindex="tabindex"
                        v-bind="attrs"
                        :disabled="disabled"
                        @click="toggle($event)"
                        v-on="selectedListeners"
                    >
                        <span class="selected-text">
                            <span class="text">
                                <slot name="selected">{{ $t('Select') }}</slot>
                            </span>
                            <slot name="icons" :opened="opened">
                                <SvgIcon width="20px" height="20px">
                                    <component :is="iconType" />
                                </SvgIcon>
                            </slot>
                        </span>
                    </button>
                </div>
                <ul v-if="optionsLoaded" v-show="opened" class="options">
                    <li v-if="$slots.beforeOptions">
                        <slot name="beforeOptions" />
                    </li>
                    <template v-if="!areOptionsGrouped">
                        <div class="options-container">
                            <li
                                v-for="(option, index) in options"
                                :key="option.id"
                                class="option"
                                :class="{
                                    'selected-id': isSelected(index),
                                    'currently-selected': isTempSelected(index),
                                }"
                                @click="selectOption(option, index)"
                                @mouseenter="setTempSelected(index)"
                            >
                                <span class="option-wrapper">
                                    <slot name="option" :option="option">
                                        {{ option.label }}
                                    </slot>
                                </span>
                            </li>
                        </div>
                    </template>
                    <template v-else>
                        <li
                            v-for="group in options"
                            :key="group.id"
                            class="group"
                        >
                            <ul>
                                <li class="option group">
                                    <slot name="group" :group="group">
                                        {{ group.label }}
                                    </slot>
                                </li>
                                <li
                                    v-for="(option, index) in group.options"
                                    :key="option.id"
                                    class="option"
                                    :class="{
                                        'selected-id': isSelected(
                                            `${group.id}-${index}`
                                        ),
                                        'currently-selected': isTempSelected(
                                            `${group.id}-${index}`
                                        ),
                                    }"
                                    @click="selectOption(option, index)"
                                    @mouseenter="
                                        setTempSelected(`${group.id}-${index}`)
                                    "
                                >
                                    <span class="option-wrapper">
                                        <slot name="option" :option="option">
                                            {{ option.label }}
                                        </slot>
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </template>
                    <li v-if="$slots.afterOptions">
                        <slot name="afterOptions" />
                    </li>
                </ul>
            </div>
        </WithClickOutsideDetection>
        <div v-if="error" class="error">
            <span class="error-msg">{{ error }}</span>
        </div>
    </div>
</template>

<script>
import { DIRECTIONS } from '@types/Dropdown';

import Up from '@static/icons/20px/up.svg?inline';
import Down from '@static/icons/20px/down.svg?inline';

import { checkIfExistsInValuesMap } from '@assets/props';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

export default {
    name: 'Dropdown',

    components: {
        WithClickOutsideDetection,
        SvgIcon,
        Up,
        Down,
    },

    inheritAttrs: false,

    props: {
        options: {
            type: Array,
            required: true,
        },

        value: {
            type: [Array, String, Number, Object, Boolean],
            required: true,
        },

        closeAfterSelect: {
            type: Boolean,
            default: true,
        },

        isSelect: {
            type: Boolean,
            default: false,
        },

        areOptionsGrouped: {
            type: Boolean,
            default: false,
        },

        error: {
            type: String,
            default: '',
        },

        direction: {
            type: String,
            default: DIRECTIONS.DIRECTION_DOWN,
            validator: checkIfExistsInValuesMap(DIRECTIONS),
        },
    },

    data() {
        return {
            optionsLoaded: false,
            opened: false,
            focused: false,
            tempSelectedId: null,
            selectedId: null,
        };
    },

    computed: {
        nativeSelectOptions() {
            const nativeOptions = this.options;

            if (!this.areOptionsGrouped) {
                return nativeOptions;
            }

            return this.options.reduce((flatten, group) => {
                return [...flatten, ...group.options];
            }, []);
        },

        disabled() {
            return this.$attrs.disabled || null;
        },

        attrs() {
            const { disabled, ...res } = this.$attrs;

            return res;
        },

        tabindex() {
            return this.isSelect ? '-1' : '0';
        },

        selectedListeners() {
            if (this.isSelect) {
                return null;
            }

            const { focus, blur, ...res } = this.$listeners;

            return {
                focus: this.onFocus,
                blur: this.onBlur,
                ...res,
            };
        },

        additionalClasses() {
            const { direction, selectedId, disabled, opened, focused } = this;

            return [
                direction,
                {
                    focused,
                    opened,
                    disabled,
                    filled: selectedId >= 0,
                },
            ];
        },

        iconType() {
            return this.opened ? Up : Down;
        },
    },

    beforeMount() {
        this.initSelectOption();
    },

    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
        },

        onBlur(event) {
            this.focused = false;
            this.$emit('blur', event);
        },

        onFocus(event) {
            this.focused = true;
            this.$emit('focus', event);
        },

        enterKeyup(event) {
            event.stopPropagation();

            if (this.focused) {
                this.toggle();
            }
        },

        toggle() {
            if (this.opened) {
                this.close();
            } else {
                this.open();
            }
        },

        open() {
            this.optionsLoaded = true;

            if (this.isSelect && this.$refs.select) {
                this.$refs.select.focus();
            }

            this.opened = true;
            this.focused = true;
            this.$emit('open');
        },

        close() {
            this.opened = false;
            this.tempSelectedId = null;
            this.$emit('close');
        },

        isTempSelected(id) {
            return this.tempSelectedId !== null && this.tempSelectedId === id;
        },

        setTempSelected(id) {
            this.tempSelectedId = id;
        },

        selectOption(option, index) {
            if (this.closeAfterSelect) {
                this.close();
            }

            this.selectedId = index;
            this.$emit('input', this.isSelect ? option.value : option);
        },

        isSelected(id) {
            return this.selectedId !== null && this.selectedId === id;
        },

        initSelectOption() {
            this.selectedId = this.options.findIndex(
                option => option.value === this.value
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/focus-visible.scss';

$errorHeight: #{theme('lineHeight.s')};
$optionsOffset: #{theme('spacing.2')};

.dropdown {
    @apply relative flex flex-col text-r leading-r;

    &.has-error {
        .select-tag {
            height: calc(100% - #{$errorHeight});
        }

        .select {
            .selected-text {
                @apply border-error border-2;

                .svg {
                    @apply text-error;
                }
            }

            &.opened {
                .options {
                    top: calc(
                        100% - #{theme('spacing.7')} - #{$optionsOffset} - #{$errorHeight}
                    );
                }
            }
        }
    }

    .select-tag {
        @apply absolute top-0 left-0 h-full w-full opacity-0;
    }

    .select {
        &.filled {
            .selected-text {
                @apply text-gray1;
            }
        }

        &.disabled {
            .selected-text {
                @apply text-gray4 bg-gray8;

                .svg {
                    @apply text-gray4;
                }
            }
        }

        &.opened {
            @apply z-3;

            .selected {
                @apply relative w-full z-2;
            }

            &.up {
                .options {
                    @apply bottom-full mt-0 mb-1;
                }
            }

            .selected-text {
                @apply text-gray1 bg-gray8 w-auto;
            }

            .options {
                @apply absolute bg-light z-2 mt-1 py-1
                       border-1 border-solid border-gray4 min-w-full;
            }

            .option {
                @apply flex items-center h-7 cursor-pointer text-gray2 px-12p relative;

                &.selected-id {
                    @apply text-gray1 bg-gray6;
                }

                &.currently-selected {
                    @apply text-gray1 bg-green5;
                }

                &.group {
                    @apply border-t-1 border-b-1 border-border px-4 font-bold text-s;
                }

                .option-wrapper {
                    @apply truncate;
                }

                &:deep() .text-link {
                    @apply flex items-center h-7 px-12p absolute top-0 left-0 right-0;
                }
            }
        }

        &.focused {
            .toggle {
                @include focusVisibleStyles();
            }
        }

        .toggle {
            @apply flex flex-col w-full items-stretch h-7 text-left justify-center;
        }

        .selected-text {
            @apply flex items-center justify-between w-full h-full flex-grow text-text
                   border-solid border-1 border-gray4 bg-light px-12p;

            .text {
                @apply truncate mr-10p;
                width: calc(100% - 20px);
            }

            &:hover {
                @apply bg-gray8;
            }
        }
    }

    .error {
        @apply text-xs leading-s mt-1;
    }

    .error-msg {
        @apply text-gray1;
    }

    @screen lg {
        .select-tag {
            @apply h-0 w-0;
        }

        .select {
            &.opened .option,
            .toggle {
                @apply h-6;
            }

            &.opened .option {
                &:deep() .text-link {
                    @apply h-6;
                }
            }
        }
    }
}
</style>
