<template>
    <label :for="id" class="base-label">
        <slot />
    </label>
</template>

<script>
export default {
    name: 'BaseLabel',

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.base-label {
    @apply cursor-pointer;
}
</style>
