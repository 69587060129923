<script>
import { createNamespacedHelpers } from 'vuex';

import { PSB_CUSTOMER_SERVICE_CHAT } from '@configs/customer-service-chat';

import { CHAT_LINKS_NAME, CHAT_LINKS } from '@localeConfig/keys';

import WhatsappColor from '@static/icons/24px/whatsapp-color.svg?inline';
import MessengerColor from '@static/icons/24px/messenger2.svg?inline';
import FormIcon from '@static/icons/24px/form.svg?inline';

const {
    mapGetters: mapCmsBlockGetters,
    mapActions: mapCmsBlockActions,
} = createNamespacedHelpers('cmsBlock');

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'CustomerServiceChatDataProvider',

    components: {
        MessengerColor,
        WhatsappColor,
    },

    data() {
        return {
            cmsBlock: {},
        };
    },

    async fetch() {
        await this.getCmsBlocks({
            cmsBlocksToLoad: [PSB_CUSTOMER_SERVICE_CHAT],
        });

        this.cmsBlock = this.staticBlockById(PSB_CUSTOMER_SERVICE_CHAT) || {};
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapConfigGetters(['contactFormLink']),

        chatLinks() {
            return this.$getLocaleConfigByKey(CHAT_LINKS_NAME);
        },

        links() {
            const {
                [CHAT_LINKS.MESSENGER_NAME]: messenger,
                [CHAT_LINKS.WHATSAPP_NAME]: whatsapp,
            } = this.chatLinks || {};

            return [
                {
                    path: messenger,
                    text: this.$t('Messenger'),
                    icon: MessengerColor,
                },
                {
                    path: whatsapp,
                    text: this.$t('Whatsapp'),
                    icon: WhatsappColor,
                },
                {
                    path: this.contactFormLink,
                    text: this.$t('Contact form'),
                    icon: FormIcon,
                },
            ].filter(({ path }) => !!path);
        },
    },

    methods: {
        ...mapCmsBlockActions(['getCmsBlocks']),
    },

    render() {
        return this.$scopedSlots.default({
            links: this.links,
            cmsBlock: this.cmsBlock,
        });
    },
};
</script>
