<template>
    <BaseButton
        :size="size"
        :theme="theme"
        :type="type"
        :to="to"
        :disabled="disabled"
        :is-loading="isLoading"
        :color="color"
        class="button-with-icon"
    >
        <div v-if="$slots.left" class="left">
            <slot name="left" />
        </div>
        <div v-if="$slots.default" class="content">
            <slot />
        </div>
        <div v-if="$slots.right" class="right">
            <slot name="right" />
        </div>
    </BaseButton>
</template>

<script>
import { COLORS } from '@types/Button';

import Button from '@mixins/Button';

import BaseButton from '@atoms/BaseButton/BaseButton';

export default {
    name: 'ButtonWithIcon',

    components: {
        BaseButton,
    },

    mixins: [Button],

    props: {
        color: {
            type: String,
            default: COLORS.COLOR_BLACK,
            validator: value => Object.values(COLORS).includes(value),
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.button-with-icon {
    .left,
    .right {
        @apply flex items-center justify-center;

        &:deep() {
            .icon {
                @apply relative;
            }
        }
    }

    .left {
        @apply mr-2;
    }

    .right {
        @apply ml-2;
    }
}
</style>
