export const TYPE_BUTTON = 'button';
export const TYPE_SUBMIT = 'submit';
export const TYPE_RESET = 'reset';

export const THEME_PRIMARY = 'primary';
export const THEME_SECONDARY = 'secondary';
export const THEME_TERTIARY = 'tertiary';
export const THEME_INITIAL = 'initial';
export const THEME_PURE = 'pure';

export const SIZE_NORMAL = 'normal';
export const SIZE_SMALL = 'small';

export const COLOR_GREEN = 'green';
export const COLOR_WHITE = 'white';
export const COLOR_BLACK = 'black';

export const TYPES = { TYPE_BUTTON, TYPE_SUBMIT, TYPE_RESET };

export const SIZES = { SIZE_NORMAL, SIZE_SMALL };

export const THEMES = {
    THEME_PRIMARY,
    THEME_SECONDARY,
    THEME_TERTIARY,
    THEME_PURE,
    THEME_INITIAL,
};

export const COLORS = {
    COLOR_GREEN,
    COLOR_WHITE,
    COLOR_BLACK,
};
