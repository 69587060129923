<template>
    <div
        ref="contentWithScroll"
        :class="[
            isEnabled ? 'with-scroll' : null,
            size,
            theme,
            isHorizontal ? 'horizontal' : null,
            desktopOnly ? 'desktop-only' : null,
            hideScrollbar ? 'hidden-scrollbar' : null,
        ]"
    >
        <slot :autoscroll-to-active-element="autoscrollToActiveElement" />
    </div>
</template>

<script>
import { THEMES, SIZES } from '@types/Scroll';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    name: 'WithScroll',

    props: {
        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        size: {
            type: String,
            default: SIZES.SIZE_SMALL,
            validator: checkIfExistsInValuesMap(SIZES, true),
        },

        isHorizontal: {
            type: Boolean,
            default: false,
        },

        desktopOnly: {
            type: Boolean,
            default: false,
        },

        isEnabled: {
            type: Boolean,
            default: true,
        },

        hideScrollbar: {
            type: Boolean,
            default: false,
        },

        autoscrollAfterChangeToLeft: {
            type: Boolean,
            default: false,
        },

        autoscrollOnInit: {
            type: Boolean,
            default: false,
        },

        autoscrollActiveClass: {
            type: String,
            default: '',
        },

        centerActiveElement: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.autoscrollAfterChangeToLeft && this.isEnabled) {
                    const { contentWithScroll = {} } = this.$refs;

                    this.setHorizontalScrollPositionToLeft(contentWithScroll);
                }
            },
        },
    },

    mounted() {
        if (this.autoscrollOnInit) {
            this.autoscrollToActiveElement();
        }
    },

    methods: {
        autoscrollToActiveElement() {
            if (!this.autoscrollActiveClass) {
                return;
            }

            this.$nextTick(() => {
                const { contentWithScroll = {} } = this.$refs;

                if (Object.keys(contentWithScroll).length === 0) {
                    return;
                }

                const activeElement = contentWithScroll.getElementsByClassName(
                    this.autoscrollActiveClass
                );

                if (activeElement.length === 0) {
                    return;
                }

                if (this.isHorizontal) {
                    this.setHorizontalScrollPosition(
                        contentWithScroll,
                        activeElement
                    );
                } else {
                    this.setVerticalScrollPosition(
                        contentWithScroll,
                        activeElement
                    );
                }
            });
        },

        setHorizontalScrollPosition(container, element) {
            const { clientWidth: contentWithScrollWidth = 0 } = container;
            const [{ offsetLeft, clientWidth }] = element;

            container.scrollLeft = this.getScrollPosition(
                offsetLeft,
                this.centerActiveElement ? contentWithScrollWidth : 0,
                this.centerActiveElement ? clientWidth : 0
            );
        },

        setHorizontalScrollPositionToLeft(container) {
            container.scrollLeft = 0;
        },

        setVerticalScrollPosition(container, element) {
            const { clientHeight: contentWithScrollHeight = 0 } = container;
            const [{ offsetTop, clientHeight }] = element;

            container.scrollTop = this.getScrollPosition(
                offsetTop,
                this.centerActiveElement ? contentWithScrollHeight : 0,
                this.centerActiveElement ? clientHeight : 0
            );
        },

        getScrollPosition(elementOffset, containerDimension, elementDimension) {
            return (
                elementOffset - containerDimension / 2 + elementDimension / 2
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin eob-with-scroll-style() {
    @apply overflow-auto relative;
    max-height: 250px;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar-track {
        @apply rounded-4;
    }

    &::-webkit-scrollbar-thumb {
        @apply rounded-4;
    }

    &.small {
        &::-webkit-scrollbar {
            @apply w-1;
        }
    }

    &.big {
        &::-webkit-scrollbar {
            @apply w-2;
        }
    }

    &.horizontal {
        @apply pb-1 max-h-none;

        &.small {
            &::-webkit-scrollbar {
                @apply h-1;
            }
        }

        &.big {
            &::-webkit-scrollbar {
                @apply h-2;
            }
        }

        @media screen and (min--moz-device-pixel-ratio: 0) {
            @apply pb-2;
        }
    }

    &.light {
        &::-webkit-scrollbar-thumb {
            @apply bg-success;
        }

        &::-webkit-scrollbar-track {
            @apply bg-gray5;
        }
    }

    &.dark {
        &::-webkit-scrollbar-thumb {
            @apply bg-gray3;
        }

        &::-webkit-scrollbar-track {
            @apply bg-gray5;
        }
    }

    &.hidden-scrollbar {
        &::-webkit-scrollbar {
            @apply w-0;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            @apply bg-transparent;
        }

        &::-webkit-scrollbar-track {
            @apply bg-transparent;
        }

        &.horizontal {
            @apply pb-0;

            &::-webkit-scrollbar {
                @apply h-0;
            }
        }
    }
}

.with-scroll:not(.desktop-only) {
    @include eob-with-scroll-style();
}

@screen lg {
    .with-scroll.desktop-only {
        @include eob-with-scroll-style();
    }
}
</style>
