<template>
    <div v-if="hasContent" class="customer-service-chat" :class="type">
        <BaseHeading v-if="!!cmsBlock.title" tag="h2" theme="h4" class="title">
            {{ cmsBlock.title }}
        </BaseHeading>
        <StaticBlock
            v-if="!!cmsBlock.content"
            class="text"
            :content="cmsBlock.content"
        />

        <div v-if="isChatWidgetAllowed || !!links.length" class="links">
            <BaseHeading
                :tag="linksHeadingTag"
                theme="h5"
                class="links-heading"
            >
                {{ $t(linksHeading) }}
            </BaseHeading>
            <slot />
            <div class="links-container">
                <ChatDataProvider
                    v-if="isChatWidgetAllowed"
                    #default="{ isChatWidgetLoading, loadAndOpenChatWidget }"
                >
                    <ButtonWithIcon
                        :disabled="isChatWidgetLoading"
                        class="link"
                        @click.native="loadAndOpenChatWidget()"
                    >
                        <template #left>
                            <SvgIcon>
                                <ChatColor />
                            </SvgIcon>
                        </template>
                        {{ $t('Start chat') }}
                    </ButtonWithIcon>
                </ChatDataProvider>

                <template v-if="!!links.length">
                    <BaseLinkStatic
                        v-for="{ path, text, icon } in links"
                        :key="path"
                        :path="path"
                        target="_blank"
                        class="link"
                    >
                        <template v-if="!!icon" #icon>
                            <SvgIcon>
                                <component :is="icon" />
                            </SvgIcon>
                        </template>
                        {{ text }}
                    </BaseLinkStatic>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    TYPE_INLINE,
    CUSTOMER_SERVICE_CHAT_TYPES,
} from '@types/CustomerServiceChat';

import ChatColor from '@static/icons/24px/chat-green.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import ButtonWithIcon from '@molecules/ButtonWithIcon/ButtonWithIcon';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import ChatDataProvider from '@molecules/ChatDataProvider/ChatDataProvider';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'CustomerServiceChat',

    components: {
        StaticBlock,
        BaseHeading,
        BaseLinkStatic,
        ButtonWithIcon,
        ContainerContent,
        ChatDataProvider,
        SvgIcon,
        ChatColor,
    },

    props: {
        links: {
            type: Array,
            default: () => [],
        },

        cmsBlock: {
            type: Object,
            default: () => ({}),
        },

        linksHeading: {
            type: String,
            default: 'We invite you to chat',
        },

        linksHeadingTag: {
            type: String,
            default: 'h3',
        },

        type: {
            type: String,
            default: TYPE_INLINE,
            validator: value =>
                Object.values(CUSTOMER_SERVICE_CHAT_TYPES).includes(value),
        },
    },

    computed: {
        ...mapConfigGetters(['isZowieChatEnabled', 'isLiveChatEnabled']),

        hasContent() {
            return (
                !!this.isChatWidgetAllowed ||
                !!this.links.length ||
                !!this.cmsBlock.title ||
                !!this.cmsBlock.content
            );
        },

        isChatWidgetAllowed() {
            return this.isLiveChatEnabled || this.isZowieChatEnabled;
        },
    },
};
</script>

<style lang="scss" scoped>
.customer-service-chat {
    @apply w-full;

    .title {
        @apply mb-3 text-primary;
    }

    .links {
        &:not(:only-child) {
            @apply mt-5;
        }
    }

    .links-container {
        @apply flex flex-col items-start justify-start;
    }

    .link {
        @apply min-h-0 font-bold text-s mt-3 px-0 cursor-pointer shadow-none;

        &:hover {
            @apply shadow-none bg-t underline;
        }
    }

    &:deep() {
        .text {
            a {
                @apply text-primary;

                &:hover {
                    @apply underline;
                }
            }
        }
    }

    &.type-inline {
        @screen xs {
            .links-container {
                @apply flex-row;
            }

            .link {
                &:not(:first-child) {
                    @apply ml-3;
                }
            }
        }

        @screen md {
            .link {
                &:not(:first-child) {
                    @apply ml-5;
                }
            }
        }

        @screen lg {
            .links {
                @apply flex items-center justify-between w-full;
            }

            .link {
                @apply mt-0;
            }
        }
    }
}
</style>
